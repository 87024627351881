import React from 'react'

import { TextProps } from '../'

import { Paragraph } from './styles'

const P = React.forwardRef((
    { className, children }: React.PropsWithChildren<TextProps>,
    ref: React.Ref<HTMLParagraphElement>
) => {
    return <Paragraph ref={ref} className={className}>{children}</Paragraph>
})

P.displayName = 'Paragraph'

export default P
