import React, { useRef, useState } from 'react'

import Arrow from 'assets/icons/arrow-down-select.svg'

import Loader from 'components/loader'
import Button from 'components/ui/button'
import Space from 'components/ui/layout/space'

import Pop from '../../ui/pop'
import Text from '../../ui/text'

import { Wrapper, button, Menu, Item, text, Title, LoaderWrapper } from './styles'

type Props = {
    secondary?: boolean
    children?: string | JSX.Element,
    onClick?: React.MouseEventHandler<HTMLDivElement>
    position?: 'left' | 'right',
    type?: 'button' | 'text',
    textColor?: 'primary' | 'secondary' | string,
    color?: string,
    options: {
        label: string | JSX.Element,
        action: (option, e: React.MouseEvent<HTMLDivElement>) => void | null
    }[],
    title?: string,
    loading?: boolean,
}

const Dropdown = ({
    options,
    children,
    position,
    type,
    textColor,
    title,
    loading,
    color,
    secondary,
    onClick
}: Props): JSX.Element => {
    const [visible, setVisible] = useState(false)

    const menuRef = useRef<HTMLDivElement>(null)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const textRef = useRef<HTMLDivElement>(null)

    return (
        <Wrapper visible={visible} tabIndex={-1} onClick={onClick}>
            {type === 'button' && (
                <Button
                    type="button"
                    className={button}
                    onClick={() => setVisible(!visible)}
                    ref={buttonRef}
                    color={color}
                    secondary={secondary}
                >
                    <Space size={10}>
                        {children}
                        <Arrow className='dropdown-arrow' />
                    </Space>
                </Button>
            )}
            {type === 'text' && (
                <Space
                    size={5}
                    className={text(textColor)}
                    ref={textRef}
                    onClick={() => setVisible(!visible)}
                >
                    <Text>{children}</Text>
                    <div className='dropdown-arrow'>
                        <Arrow />
                    </div>
                </Space>
            )}
            <Pop target={type === 'button' ? buttonRef : textRef} align={position} visible={visible} onClose={() => setVisible(false)}>
                <Menu visible={visible} ref={menuRef} position={position}>
                    {title && <Title index={0} visible={visible}>{title}</Title>}
                    {loading ? (
                        <LoaderWrapper>
                            <Loader />
                        </LoaderWrapper>
                    ) : (
                        options.shift && options.map((option, index) => (
                            <Item
                                key={index}
                                index={index}
                                visible={visible}
                                onClick={(e) => {
                                    option.action(option, e)
                                    setVisible(false)
                                }}
                            >
                                {option.label}
                            </Item>
                        ))
                    )}
                </Menu>
            </Pop>
        </Wrapper>
    )
}

Dropdown.defaultProps = {
    position: 'left',
    type: 'button',
    textColor: 'secondary'
}

export default Dropdown
