import styled from '@emotion/styled'

import { TransitionStatus } from 'react-transition-group'

import { setTransition } from 'utils/style'

import { colors } from 'settings/theme'

import MenuRow from './row'

export const Wrapper = styled.div<{state: TransitionStatus, w: number | undefined}>`
  overflow-x: hidden;
  background-color: ${colors.white};
  transition: all 200ms;
  width: ${({ state, w }) => setTransition(state, {
        active: w,
        disabled: 40
    })}px;
  height: 100%;
  min-height: 100vh;
  box-shadow: -4px 1px 15px #c8c8c8;
  font-size: 20px;
  &:focus {
    outline: none;
    border: none;
  }
`

export const DrawerButtonWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  cursor: pointer;
`

export const MenuWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const CollapseWrapper = styled(DrawerButtonWrapper)`
  border-bottom: 1px solid #cecece;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
`

export const MenuRowAdmin = styled(MenuRow)`
  a > div {
    color: ${colors.error};
  }
`

export const MenuRowCompany = styled(MenuRow)`
  a > div {
    color: ${colors.primary};
  }
`
