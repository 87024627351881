import styled from '@emotion/styled'

import React from 'react'

import { setSize } from 'utils/style'

type Props = {
    // breakpoint in pixels
    bp: number
    children: JSX.Element | JSX.Element[] | string
} & StyleProps

const Container = styled.div<Props>`
    width: ${({ w }) => setSize(w || '100%')};
    display: contents;
    @media (max-width: ${({ bp }) => setSize(bp)}) {
        display: none;
    }
`

const Hide = ({ bp, children, ...props }: Props): JSX.Element => (
    <Container bp={bp} {...props}>
        {children}
    </Container>
)

export default Hide
