import styled from '@emotion/styled'

import { TransitionStatus } from 'react-transition-group'

import { setTransition } from 'utils/style'

import { colors } from 'settings/theme'

export const Wrapper = styled.div<{state: TransitionStatus, w: number | undefined}>`
  overflow-x: hidden;
  background-color: ${colors.white};
  transition: all 200ms;
  width: ${({ state, w }) => setTransition(state, {
        active: w,
        disabled: 40
    })}px;
  height: 100%;
  min-height: 100vh;
  box-shadow: -4px 1px 15px #c8c8c8;
  font-size: 20px;
  &:focus {
    outline: none;
    border: none;
  }
`

export const IconWrapper = styled.div<{ clickable?: boolean }>`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  cursor: pointer;
`

export const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const Title = styled.div`
  font-size: 16px;
  color: #b1b1b1;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin-left: 38px;
  padding: 5px;
  gap: 3px;
`

export const Item = styled.div<{active?: boolean, clickable?: boolean}>`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 3px;
  cursor: pointer;
  overflow: hidden;
  color: ${({ active }) => active ? 'white' : '#525454'};
  font-size: 16px;
`

export const Category = styled.div`
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  font-weight: 500;
`

export const Options = styled(Category)`
  margin-left: 43px;
  display: grid;
  grid-gap: 15px;
  padding-bottom: 20px;
  gap: 15px;
  color: #525454;
`

export const Row = styled.div<{active?: boolean}>`
  width: 100%;
  border-bottom: 1px solid rgba(204, 204, 204, 0.35);
  background-color: ${({ active }) => active ? colors.primary : 'white'};
  transition: all 200ms;
  &:first-of-type {
    border-top: 1px solid rgba(204, 204, 204, 0.35);
  }
  &:hover {
    background-color: ${({ active }) => active ? colors.primary + 'e4' : 'rgba(0, 0, 0, 0.02)'};
  }
`

export const IconWrapperArrow = styled(IconWrapper)<{state: TransitionStatus}>`
  transition: all 200ms;
  transform: rotate(${({ state }) => setTransition<string | number>(state, { active: '180deg', disabled: 0 })}deg);
`

export const OptionsWrapper = styled.div<{state: TransitionStatus, contentHeight: number}>`
  width: 100%;
  transition: all 150ms;
  height: ${({ state, contentHeight }) => setTransition(state, { active: contentHeight, disabled: 0 })}px;
  overflow: hidden;
`

export const CollapseWrapper = styled(IconWrapper)`
  border-bottom: 1px solid #cecece;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
`
