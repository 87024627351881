import NextLink from 'next/link'

import React from 'react'

import { goTo } from 'utils/dom'

import { Content } from './styles'

type Props = {
    to: string,
    seo?: boolean,
    target?: string,
    className?: string,
    children: Children,
}

const Link = ({ children, to, seo, target, className, ...props }: Props): JSX.Element => {
    const handleClick = e => {
        if (to.charAt(0) === '#') {
            // e.stopPropagation()
            e.preventDefault()
            goTo(to.substring(1))
            // location.hash = to
        }
    }
    return (
        <Content {...props}>
            <NextLink href={to} passHref={seo} className={className}>
                <a onClick={handleClick} target={target}>{children}</a>
            </NextLink>
        </Content>
    )
}

Link.defaultProps = {
    seo: false
}

export default Link
