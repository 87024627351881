import { css } from '@emotion/css'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { setSize } from 'utils/style'

import { global, colors, button as buttonTheme, zIndex } from 'settings/theme'

export const Wrapper = styled.div<{visible: boolean}>`
  position: relative;
  display: flex;
  flex-direction: column;
  .dropdown-arrow {
    transition: all 500ms;
    transform: translateY(1px) ${({ visible: v }) => v ? 'scale(-1, -1)' : 'scale(1, 1)'};
  }
`

export const button = css`
  padding-right: ${global.padding + 5}px;
`

const open = keyframes`
  from {
    transform: scale(1, 0);
    opacity: 0;
  }  
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
`

const optionOpen = keyframes`
  from {
    opacity: 0;
    transform: translateY(-5px);
  }  
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Menu = styled.div<{visible: boolean, position: 'left' | 'right'}>`
  margin-top: 10px;
  padding: 10px 0;
  z-index: ${zIndex.pop};
  width: max-content;
  position: absolute;
  top: 100%;
  ${({ position: p }) => p}  : 0;
  border-radius: ${global.br}px;
  font-size: ${setSize(global.fs)};
  background-color: white;
  z-index: ${zIndex.pop};
  opacity: 0;
  transform: scale(1, 0);
  transform-origin: top;
  box-shadow: ${global.shadowLight};
  overflow-y: auto;
  animation: ${({ visible }) => visible && open} 300ms forwards;
`

export const Item = styled.div<{visible: boolean, index: number}>`
  z-index: 1;
  padding: 0 ${global.padding}px;
  height: 40px;
  font-size: ${global.fs}px;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: all 300ms;
  animation: ${({ visible }) => visible && optionOpen} 400ms ${({ index }) => 200 + 20 * index}ms forwards;
  &:hover {
    background-color: ${colors.primary + 0x10};
  }
`

export const text = (textColor: 'primary' | 'secondary' | string): string => css`
  color: ${colors[textColor] || textColor};
  cursor: pointer;
  .dropdown-arrow {
    transform: scale(0.7) translateY(-1px);
  }
`

export const Title = styled(Item)`
  padding: 0 ${global.padding}px;
  height: ${buttonTheme.height + 10}px;
  font-size: ${global.fs}px;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: all 300ms;
  animation: none;
  font-weight: bold;
  &:hover {
    background-color: ${colors.primary + 0x40};
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`
